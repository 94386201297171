import { MdWork } from "react-icons/md"

import Contrat from '../../assets/img/contrat.jpg';
import Recrut from '../../assets/img/recrut.jpg';
import Franc from '../../assets/img/franc.jpg';
import assMed from '../../assets/img/assmed.jpg';
import Formation from '../../assets/img/formation.jpg';
import Visa from '../../assets/img/visa.jpeg';
import { useTranslation } from 'react-i18next';

export const EmployementSection = () => {
    const { t } = useTranslation();
    return (

        <div>
            <div className='flex gap-3 justify-left text-3xl mt-28'>
                <span className='font-bold flex items-center gap-2'><MdWork size={40} className='text-[#87ceeb]' />SOFKAM {t('serv_3_title')}</span>
            </div>
            <div className='flex justify-leftt'>
                <div className='min-w-[150px] h-[5px] bg-[#87ceeb] rounded-3xl mt-5' />
            </div>

            <div className='flex flex-col justify-left text-left mt-5'>
                <span className='text-xl font-bold '>{t('serv3_p1')}</span>
                <span className='mt-5'>{t('serv3_p2')}</span>
            </div>



            <div className='mt-5 flex flex-col'>
                <span className='font-bold text-xl text-[#87ceeb]'>A. {t('s3_sub1_title')}</span>
                <div className='flex flex-wrap'>
                    <div className='w-full xl:w-1/2 p-4'>
                        <img src={Recrut} alt="Recrut" className='w-full h-auto rounded-3xl' />
                    </div>
                    <div className='flex items-center  py-10 w-full xl:w-1/2 p-4 '>
                        <div className='bg-[#87ceeb] rounded-3xl p-8 font-bold text-lg'>
                            {t('s3_sub1_info')}
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-5 flex flex-col'>
                <span className='font-bold text-xl text-[#87ceeb]'>B. {t('s3_sub2_title')}</span>
                <div className='flex flex-wrap'>
                    <div className='flex items-center  py-10 w-full xl:w-1/2 p-4 '>
                        <div className='bg-[#87ceeb] rounded-3xl p-8 font-bold text-lg'>
                            {t('s3_sub2_info')}

                        </div>
                    </div>
                    <div className='w-full xl:w-1/2 p-4'>
                        <img src={Contrat} alt="Placement" className='w-full h-auto rounded-3xl' />
                    </div>
                </div>
            </div>

            <div className='mt-5 flex flex-col'>
                <span className='font-bold text-xl text-[#87ceeb]'>C. {t('s3_sub3_title')}</span>
                <div className='flex flex-wrap'>
                    <div className='w-full xl:w-1/2 p-4'>
                        <img src={Franc} alt="Paie" className='w-full h-auto rounded-3xl' />
                    </div>
                    <div className='flex items-center  py-10 w-full xl:w-1/2 p-4 '>
                        <div className='bg-[#87ceeb] rounded-3xl p-8 font-bold text-lg'>
                            {t('s3_sub3_info')}
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-5 flex flex-col'>
                <span className='font-bold text-xl text-[#87ceeb]'>D. {t('s3_sub4_title')}</span>
                <div className='flex flex-wrap'>
                    <div className='flex items-center  py-10 w-full xl:w-1/2 p-4 '>
                        <div className='bg-[#87ceeb] rounded-3xl p-8 font-bold text-lg'>
                            {t('s3_sub4_info')}
                        </div>
                    </div>
                    <div className='w-full xl:w-1/2 p-4'>
                        <img src={assMed} alt="assMed" className='w-full h-auto rounded-3xl' />
                    </div>
                </div>
            </div>



            <div className='mt-5 flex flex-col'>
                <span className='font-bold text-xl text-[#87ceeb]'>E. {t('s3_sub5_title')}</span>
                <div className='flex flex-wrap'>
                    <div className='w-full xl:w-1/2 p-4'>
                        <img src={Visa} alt="Paie" className='w-full h-auto rounded-3xl' />
                    </div>
                    <div className='flex items-center  py-10 w-full xl:w-1/2 p-4 '>
                        <div className='bg-[#87ceeb] rounded-3xl p-8 font-bold text-lg'>
                            {t('s3_sub5_info')}
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-5 flex flex-col'>
                <span className='font-bold text-xl text-[#87ceeb]'>F.{t('s3_sub6_title')}</span>
                <div className='flex flex-wrap'>
                    <div className='flex items-center  py-10 w-full xl:w-1/2 p-4 '>
                        <div className='bg-[#87ceeb] rounded-3xl p-8 font-bold text-lg'>
                            {t('s3_sub6_info')}
                            <div className='flex flex-col ml-5'>
                                <span>- {t('s3_sub6_info-1')}</span>
                                <span>- {t('s3_sub6_info-2')}</span>
                                <span>- {t('s3_sub6_info-3')}</span>
                            </div>
                        </div>
                    </div>
                    <div className='w-full xl:w-1/2 p-4'>
                        <img src={Formation} alt="assMed" className='w-full h-auto rounded-3xl' />
                    </div>
                </div>
            </div>
        </div>

    )
}