import { FaMoneyBill } from "react-icons/fa";
import Transfert from '../../assets/img/transfert.jpg';
import Assurance from '../../assets/img/assurance.jpg';
import { useTranslation } from 'react-i18next';

export const FinanceSection = () => {
    const { t } = useTranslation();
    return (
        <div>
            <div className='flex gap-3 justify-left text-3xl mt-28'>
                <span className='font-bold flex items-center gap-2'><FaMoneyBill size={40} className='text-[#87ceeb]' />SOFKAM-{t('serv_7_title')}</span>
            </div>
            <div className='flex justify-leftt'>
                <div className='min-w-[150px] h-[5px] bg-[#87ceeb] rounded-full mt-5' />
            </div>

            <div className='mt-5 flex flex-col'>
                <span className='font-bold text-xl text-[#87ceeb]'>A. {t('s7_sub1_title')}</span>
                <div className='flex flex-wrap'>
                    <div className='w-full xl:w-1/2 p-4'>
                        <img src={Transfert} alt="Transfert" className='w-full h-auto rounded-3xl' />
                    </div>
                    <div className='flex items-center  py-10 w-full xl:w-1/2 p-4 '>
                        <div className='flex flex-col bg-[#87ceeb] rounded-3xl p-8 font-bold text-lg'>
                            <span>{t('s7_sub1_info-1')}</span>
                            <span>{t('s7_sub1_info-2')}, </span>
                            <span>{t('s7_sub1_info-3')}</span>
                            <span>{t('s7_sub1_info-4')}</span>
                            <span>{t('s7_sub1_info-5')}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-5 flex flex-col'>
                <span className='font-bold text-xl text-[#87ceeb]'>B. {t('s7_sub2_title')}</span>
                <div className='flex flex-wrap'>
                    <div className='flex items-center  py-10 w-full xl:w-1/2 p-4 '>
                        <div className='flex flex-col bg-[#87ceeb] rounded-3xl p-8 font-bold text-lg'>
                            <span>{t('s7_sub2_info-1')}</span>
                            <span>{t('s7_sub2_info-2')}</span>
                            <span>- {t('s7_sub2_info-3')}</span>
                            <span>- {t('s7_sub2_info-4')}</span>
                            <span>- {t('s7_sub2_info-5')}</span>
                            <span>- {t('s7_sub2_info-6')}</span>
                            <span>- {t('s7_sub2_info-7')}</span>
                        </div>
                    </div>
                    <div className='w-full xl:w-1/2 p-4'>
                        <img src={Assurance} alt="Assurance" className='w-full h-auto rounded-3xl' />
                    </div>
                </div>
            </div>
        </div>
    )
}