import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-scroll';
import Logo from '../assets/img/sofkam.png';
import enFlag from '../assets/profile/en.png';
import frFlag from '../assets/profile/fr.png';
import spFlag from '../assets/profile/sp.png';
import lnFlag from '../assets/profile/ln.png';
import swFlag from '../assets/profile/sw.png';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'flowbite-react';
import { HiMenu } from 'react-icons/hi';
import { FaArrowRight } from 'react-icons/fa';
import { useOutsideFinder } from '../utils/useOutsideFinder';
import { MdClose } from 'react-icons/md';

export const Header = () => {
    const [language, setLanguage] = useState(enFlag);
    const { t, i18n } = useTranslation();
    const [activeSection, setActiveSection] = useState('home');
    const [show, setShow] = useState(false)
    const divRef = useRef()

    const handleSetActive = (to) => {
        setActiveSection(to);
    };

    useEffect(() => {
        // Récupérez la langue actuelle depuis le sessionStorage ou utilisez 'en' par défaut
        const currentLanguage = sessionStorage.getItem("i18next") || "en";
        setLanguage(currentLanguage === "en" ? enFlag
            : currentLanguage === "fr" ? frFlag
                : currentLanguage === "sp" ? spFlag
                    : currentLanguage === "sw" ? swFlag
                        : lnFlag);
    }, []);

    const handleLanguageChange = (newLanguage) => {
        // Mettez à jour le sessionStorage et la langue dans le composant
        sessionStorage.setItem("i18next", newLanguage);
        setLanguage(
            newLanguage === "en" ? enFlag
                : newLanguage === "fr" ? frFlag
                    : newLanguage === "sp" ? spFlag
                        : newLanguage === "sw" ? swFlag
                            : lnFlag
        );

        i18n.changeLanguage(newLanguage);
    };

    useEffect(() => {
        const handleScroll = () => {
            const servicesOffset = document.getElementById('services').offsetTop;
            const aboutUsOffset = document.getElementById('aboutUs').offsetTop;
            const contactUsOffset = document.getElementById('contactUs').offsetTop;

            const scrollPosition = window.scrollY;

            if (scrollPosition < servicesOffset) {
                setActiveSection('home');
            } else if (scrollPosition >= servicesOffset && scrollPosition < (aboutUsOffset - 100)) {
                setActiveSection('services');
            } else if (Math.round(scrollPosition) >= (aboutUsOffset - 100) && scrollPosition < (contactUsOffset - 100)) {
                setActiveSection('aboutUs');
            } else {
                setActiveSection('contactUs');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useOutsideFinder(() => setShow(false), divRef)
    return (
        <>
            <div className="bg-white text-black sm:h-24 h-20 flex items-center justify-between 2xl:px-[450px] xl:px-[350px] lg:px-60 md:px-32 px-5 fixed top-0 w-full z-10">
                <Link to="home" smooth={true} duration={500} onSetActive={handleSetActive}>
                    <img src={Logo} alt="Sofkam Logo" className='sm:h-20 sm:w-20 h-10 w-10 min-h-10 min-w-10 cursor-pointer' />
                </Link>

                <div className='font-extrabold italic uppercase text-2xl text-[#87ceeb]'>
                    SOFKAM
                </div>

                <div className='ml-4 sm:flex hidden items-center gap-4'>
                    <Link to="home" smooth={true} duration={500} onSetActive={handleSetActive}>
                        <div onClick={() => { setShow(false) }} className={`flex items-center  ${activeSection === 'home' && 'underline decoration-[#87ceeb] decoration-4'}`} >
                            <label className='cursor-pointer'>{t('H1')}</label>
                        </div>
                    </Link>
                    <Link to="services" smooth={true} duration={500} onSetActive={handleSetActive}>
                        <div onClick={() => { setShow(false) }} className={`flex items-center  ${activeSection === 'services' && 'underline decoration-[#87ceeb] decoration-4'}`}>
                            <label className='cursor-pointer'>
                                {t('H2')}
                            </label>
                        </div>
                    </Link>
                    <Link to="aboutUs" smooth={true} duration={500} onSetActive={handleSetActive}>
                        <div onClick={() => { setShow(false) }} className={`flex items-center  ${activeSection === 'aboutUs' && 'underline decoration-[#87ceeb] decoration-4'}`}>
                            <label className='cursor-pointer'>{t('H3')}</label>
                        </div>
                    </Link>
                    <Link to="contactUs" smooth={true} duration={500} onSetActive={handleSetActive}>
                        <div onClick={() => { setShow(false) }} className={`flex items-center  ${activeSection === 'contactUs' && 'underline decoration-[#87ceeb] decoration-4'}`}>
                            <label className='cursor-pointer'>{t('H4')}</label>
                        </div>
                    </Link>

                    <div className="flex md:order-2">
                        <Dropdown
                            arrowIcon={false}
                            inline
                            label={
                                <img src={language} alt="langue" className='h-7 w-7 min-h-7 min-w-7 cursor-pointer rounded-full' />
                            }
                        >
                            <Dropdown.Item className='hover:bg-blue-100' onClick={() => handleLanguageChange("en")}>
                                <img src={enFlag} alt="langue" className='h-7 w-7 min-h-7 min-w-7 cursor-pointer rounded-full' />
                            </Dropdown.Item>
                            <Dropdown.Item className='hover:bg-blue-100' onClick={() => handleLanguageChange("fr")}>
                                <img src={frFlag} alt="langue" className='h-7 w-7 min-h-7 min-w-7 cursor-pointer rounded-full' />
                            </Dropdown.Item>
                            <Dropdown.Item className='hover:bg-blue-100' onClick={() => handleLanguageChange("sp")}>
                                <img src={spFlag} alt="langue" className='h-7 w-7 min-h-7 min-w-7 cursor-pointer rounded-full' />
                            </Dropdown.Item>
                            <Dropdown.Item className='hover:bg-blue-100' onClick={() => handleLanguageChange("ln")}>
                                <img src={lnFlag} alt="langue" className='h-7 w-7 min-h-7 min-w-7 cursor-pointer rounded-full' />
                            </Dropdown.Item>
                            <Dropdown.Item className='hover:bg-blue-100' onClick={() => handleLanguageChange("sw")}>
                                <img src={swFlag} alt="langue" className='h-7 w-7 min-h-7 min-w-7 cursor-pointer rounded-full' />
                            </Dropdown.Item>
                        </Dropdown>
                    </div>

                </div>

                <div className='flex sm:hidden hover:text-[#87ceeb]' >
                    {!show ?
                        <HiMenu size={25} onClick={() => { setShow(true) }} />
                        :
                        <MdClose size={25} onClick={() => { setShow(false) }} />
                    }
                </div>
            </div>

            <div ref={divRef} className={`min-w-full sm:hidden ${show ? 'flex animate-slide-in' : 'hidden '} bg-slate-200 shadow-xl mt-20 z-50 fixed flex-col `}>
                <Link to="home" smooth={true} duration={500} onSetActive={handleSetActive} onClick={() => { setShow(false); }}>
                    <div className={`flex items-center justify-between border-b border-[#87ceeb] hover:bg-[#87ceeb] p-3  ${activeSection === 'home' && 'underline decoration-[#87ceeb] decoration-4'}`} >
                        <label className='cursor-pointer'>{t('H1')}</label>
                        <FaArrowRight size={15} />
                    </div>
                </Link>
                <Link to="services" smooth={true} duration={500} onSetActive={handleSetActive} onClick={() => { setShow(false); }}>
                    <div className={`flex items-center justify-between border-b border-[#87ceeb] hover:bg-[#87ceeb] p-3  ${activeSection === 'services' && 'underline decoration-[#87ceeb] decoration-4'}`}>
                        <label className='cursor-pointer'>
                            {t('H2')}
                        </label>
                        <FaArrowRight size={15} />
                    </div>
                </Link>
                <Link to="aboutUs" smooth={true} duration={500} onSetActive={handleSetActive} onClick={() => { setShow(false); }}>
                    <div className={`flex items-center justify-between border-b border-[#87ceeb] hover:bg-[#87ceeb] p-3  ${activeSection === 'aboutUs' && 'underline decoration-[#87ceeb] decoration-4'}`}>
                        <label className='cursor-pointer'>{t('H3')}</label>
                        <FaArrowRight size={15} />
                    </div>
                </Link>
                <Link to="contactUs" smooth={true} duration={500} onSetActive={handleSetActive} onClick={() => { setShow(false); }}>
                    <div className={`flex items-center justify-between border-b border-[#87ceeb] hover:bg-[#87ceeb] p-3  ${activeSection === 'contactUs' && 'underline decoration-[#87ceeb] decoration-4'}`}>
                        <label className='cursor-pointer'>{t('H4')}</label>
                        <FaArrowRight size={15} />
                    </div>
                </Link>

                <div className="flex md:order-2 p-3">
                    <Dropdown
                        arrowIcon={false}
                        inline
                        label={<div className='flex px-2 gap-2 justify-between'>
                            <span>Language</span>
                            <img src={language} alt="langue" className='h-7 w-7 min-h-7 min-w-7 cursor-pointer rounded-full' />
                        </div>
                        }
                    >
                        <Dropdown.Item className='hover:bg-blue-100 flex justify-between gap-3 px-2' onClick={() => { handleLanguageChange("en"); setShow(false) }}>
                            <span>English</span>
                            <img src={enFlag} alt="langue" className='h-7 w-7 min-h-7 min-w-7 cursor-pointer rounded-full' />
                        </Dropdown.Item>
                        <Dropdown.Item className='hover:bg-blue-100 flex justify-between gap-3 px-2' onClick={() => { handleLanguageChange("fr"); setShow(false) }}>
                            <span>Francais</span>
                            <img src={frFlag} alt="langue" className='h-7 w-7 min-h-7 min-w-7 cursor-pointer rounded-full' />
                        </Dropdown.Item>
                        <Dropdown.Item className='hover:bg-blue-100 flex justify-between gap-3 px-2' onClick={() => { handleLanguageChange("sp"); setShow(false) }}>
                            <spain>española</spain>
                            <img src={spFlag} alt="langue" className='h-7 w-7 min-h-7 min-w-7 cursor-pointer rounded-full' />
                        </Dropdown.Item>
                        <Dropdown.Item className='hover:bg-blue-100 flex justify-between gap-3 px-2' onClick={() => { handleLanguageChange("ln"); setShow(false) }}>
                            <span>Lingala</span>
                            <img src={lnFlag} alt="langue" className='h-7 w-7 min-h-7 min-w-7 cursor-pointer rounded-full' />
                        </Dropdown.Item>
                        <Dropdown.Item className='hover:bg-blue-100 flex justify-between gap-3 px-2' onClick={() => { handleLanguageChange("sw"); setShow(false) }}>
                            <span>Kiswahili</span>
                            <img src={swFlag} alt="langue" className='h-7 w-7 min-h-7 min-w-7 cursor-pointer rounded-full' />
                        </Dropdown.Item>
                    </Dropdown>
                </div>
            </div>
        </>
    );
};

export default Header;
