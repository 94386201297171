import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import Popup from './popup';
const url = process.env.REACT_APP_URL;

export const ContactUs = () => {
    const { t } = useTranslation();
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [pop, setPop] = useState(false)

    const handleSent = (e) => {
        e.preventDefault()
        if (message.length > 0) {
            axios.post(url + '/send', {
                name,
                email,
                message
            }).then((response) => {
                setPop(true)
                setName("")
                setEmail("")
                setMessage("")
            })
        } else {
            alert('Complete the Message field')
        }
    }


    return (
        <div id="contactUs" className='2xl:px-[450px] xl:px-[350px] lg:px-60 md:px-32 px-5 bg-gray-200 '>
            <Popup open={pop} setOpen={setPop} />
            <div className='flex gap-3 justify-center text-5xl pt-24'>
                <span className='font-bold'>{t('contact-1')}</span>
                <span>{t('contact-2')}</span>
            </div>
            <div className='flex justify-center'>
                <div className='min-w-[150px] h-[5px] bg-[#87ceeb] rounded-full mt-5' />
            </div>

            <div>
                <div className="border-b border-gray-900/10 pb-12">
                    <form onSubmit={handleSent}>
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="first-name"
                                        id="first-name"
                                        autoComplete="given-name"
                                        placeholder={t('cont-field1')}
                                        onChange={(e) => { setName(e.target.value) }}
                                        value={name}
                                        required
                                        className="block w-full rounded-md border-0 h-14 p-2 focus:outline-none text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#87ceeb] text-sm sm:text-2xl sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <div className="mt-2">
                                    <input
                                        placeholder={t('cont-field2')}
                                        type="email"
                                        name="email"
                                        id="email"
                                        autoComplete="email"
                                        onChange={(e) => { setEmail(e.target.value) }}
                                        value={email}
                                        required
                                        className="block w-full rounded-md border-0 h-14 p-2 focus:outline-none text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#87ceeb] text-sm sm:text-2xl sm:leading-6"
                                    />
                                </div>
                            </div>

                            {/* 
                            <div className="sm:col-span-3">
                                <div className="mt-2">
                                    <input
                                        placeholder={t('cont-field3')}
                                        type="text"
                                        name="Company"
                                        id="Company"
                                        autoComplete="Company"
                                        onChange={(e) => { setMessage(e.target.value) }}
                                        value={message}
                                        required
                                        className="block w-full rounded-md border-0 h-14 p-2 focus:outline-none text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#87ceeb] text-sm sm:text-2xl sm:leading-6"
                                    />
                                </div>
                            </div>
                        <div className="sm:col-span-3">
                            <div className="mt-2">
                                <input
                                    placeholder={t('cont-field4')}
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    autoComplete="phone"
                                    className="block w-full rounded-md border-0 h-14 p-2 focus:outline-none text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#87ceeb] text-sm sm:text-2xl sm:leading-6"
                                />
                            </div>
                        </div> */}

                            <div className="col-span-full">
                                <div className="mt-2">
                                    <textarea
                                        placeholder={t('cont-field5')}
                                        id="about"
                                        name="about"
                                        rows={7}
                                        onChange={(e) => { setMessage(e.target.value) }}
                                        value={message}
                                        className="block w-full rounded-md border-0 p-2 focus:outline-none text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#87ceeb] text-sm sm:text-2xl sm:leading-6"
                                    />
                                </div>

                            </div>
                        </div>

                        <div className='flex items-center justify-end w-full mt-10'>
                            <button className="text-red hover:before:bg-redborder-[#87ceeb] relative h-[50px] w-40 overflow-hidden border border-[#87ceeb] bg-white px-3 text-[#87ceeb] shadow-2xl transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-[#87ceeb] before:transition-all before:duration-500 hover:text-white hover:shadow-[#87ceeb] hover:before:left-0 hover:before:w-full">
                                <span className="relative z-0">{t('cont-Btn')}</span>
                            </button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    )
}