import Building from '../assets/img/building.jpg';
import { useTranslation } from 'react-i18next';

export const Home = () => {
    const { t } = useTranslation();
    return (
        <>
            <img src={Building} alt="Building" className='w-full h-full object-cover opacity-70' />

            <div className="absolute top-1/2 left-0 transform -translate-y-1/2 2xl:px-[450px] xl:px-[350px] lg:px-60 md:px-32 px-5">
                <div className="bg-[#87ceeb] bg-opacity-70 p-4 rounded-full">
                    <h1 className="text-4xl sm:text-6xl font-bold text-white text-center">{t('welcome')}</h1>
                    <p className="text-lg sm:text-2xl text-white text-center">{t('slogan')}</p>
                    {/* <div className="flex items-center justify-end italic text-lg sm:text-xl text-white font-bold underline cursor-pointer hover:text-gray-200">
                        <a href='https://cd.linkedin.com/in/shams-kibonge-5623a7260'>
                            SHAMS <span className='ml-2 font-thin'>Web developer.</span>
                        </a>
                    </div> */}
                </div>
            </div>
        </>
    )
}