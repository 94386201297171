import Building from '../assets/img/building.jpg';
import Shams from '../assets/profile/shams.jpg';
import Dev from '../assets/profile/dev.png';
import { useTranslation } from 'react-i18next';

const PersonCard = ({ name, imageUrl, job, Ico }) => {
    return (
        <div className="relative w-72 h-96 m-4 overflow-hidden">
            <img src={imageUrl} alt={name} className="w-full h-full object-cover rounded-2xl" />
            <div className="absolute bottom-0 w-full text-[#106396]">
                <div className="flex items-center gap-2 text-center font-bold bg-[#87ceeb] opacity-85 p-4 mx-4 my-2 rounded-2xl font-serif italic">

                    <img src={Ico} alt={`Ico ${name}`} className="w-10 h-10" />
                    <div className='text-left'>
                        <div className='text-lg'>{name}</div>
                        <div className='text-sm'>{job}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const AboutUs = () => {
    const { t } = useTranslation();
    return (
        <div id='aboutUs' className='2xl:px-[450px] xl:px-[350px] lg:px-60 md:px-32 px-5 bg-gray-300'>
            <div className='flex gap-3 justify-center text-5xl pt-28'>
                <span className='font-bold'>{t('about-1')}</span>
                <span>{t('about-2')}</span>
            </div>
            <div className='flex justify-center'>
                <div className='min-w-[150px] h-[5px] bg-[#87ceeb] rounded-full mt-5' />
            </div>

            <div className='mt-10'>
                <img src={Building} alt="Building" className='w-full h-full object-cover opacity-70' />
                <div className='flex flex-wrap'>
                    <div className='flex items-center py-10 w-full 2xl:w-1/2 p-4 '>
                        <div className='font-bold text-lg'>
                            {t('ab-1')}
                        </div>
                    </div>
                    <div className='w-full 2xl:w-1/2 p-4'>
                        <img src={Building} alt="Assurance" className='w-full h-auto rounded-3xl' />
                    </div>
                </div>
                <div className='flex flex-wrap'>
                    <div className='w-full 2xl:w-1/2 p-4'>
                        <img src={Building} alt="Assurance" className='w-full h-auto rounded-3xl' />
                    </div>
                    <div className='flex items-center  py-10 w-full 2xl:w-1/2 p-4 '>
                        <div className='flex flex-col font-bold text-lg'>
                            <span className='text-[#87ceeb]'>
                                {t('ab-2')}
                            </span>
                            <span>
                                {t('ab-3')}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="flex flex-wrap">
                    <PersonCard imageUrl={Shams} name="SHAMS KIBONGE" job={'Web Designer'} Ico={Dev} />
                    {/* <PersonCard imageUrl={Shams} name="SHAMS KIBONGE" job={'Web Designer'} Ico={Dev} />
                    <PersonCard imageUrl={Shams} name="SHAMS KIBONGE" job={'Web Designer'} Ico={Dev} /> */}
                </div>
            </div>

        </div>
    )
}