import { FaBuilding, FaHospitalSymbol, FaMoneyBill, FaTruck } from 'react-icons/fa';
import { MdSecurity, MdSupportAgent, MdWork } from "react-icons/md";
import { GiMining } from "react-icons/gi";
import { BuildingSection } from './Services/Building';
import { HospitalSection } from './Services/Hospital';
import { EmployementSection } from './Services/Employement';
import { LogistiqueSection } from './Services/Logistique';
import { ConsultingSection } from './Services/Consulting';
import { SecuritySection } from './Services/Security';
import { FinanceSection } from './Services/Finances';
import { useTranslation } from 'react-i18next';

import { Element, Link } from 'react-scroll';

const Card = ({ title, content, icon }) => {
    const { t } = useTranslation();
    const IconComponent = icon;
    return (
        <div className='p-4 mb-4'>
            <div className='flex flex-col items-center text-center'>
                <IconComponent size={100} className='text-[#87ceeb]' />
                <label className='mt-5 font-bold text-xl uppercase'>{title}</label>
            </div>
            <div className='h-[180px] overflow-hidden'>
                <p className='mt-4 text-center'>{content}</p>
            </div>
            <Link to={title} smooth={true} duration={500}>
                <div className='flex items-center justify-center bg-[#87ceeb] rounded text-white mt-5 cursor-pointer py-2'>
                    {t('Btn1')}
                </div>
            </Link>
        </div>
    );
};

export const Services = () => {
    const { t } = useTranslation();
    return (
        <div id='services' className='bg-gray-200 2xl:px-[450px] xl:px-[350px] lg:px-60 md:px-32 px-5 '>
            <div className='flex gap-3 justify-center text-5xl pt-28'>
                <span>{t('service-1')}</span>
                <span className='font-bold'>{t('service-2')}</span>
            </div>
            <div className='flex justify-center'>
                <div className='min-w-[150px] h-[5px] bg-[#87ceeb] rounded-full mt-5' />
            </div>

            <div className='flex justify-center text-center mt-10'>
                {t('service-intro')}
            </div>

            <div className='grid gap-4  lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mt-20'>
                <Card
                    content={t('serv_1_info')}
                    icon={FaBuilding}
                    title={t('serv_1_title')}
                />

                <Card
                    content={t('serv_2_info')}
                    icon={FaHospitalSymbol}
                    title={t('serv_2_title')}
                />

                <Card
                    content={t('serv_3_info')}
                    icon={MdWork}
                    title={t('serv_3_title')}
                />

                <Card
                    content={t('serv_4_info')}
                    icon={FaTruck}
                    title={t('serv_4_title')}
                />

                <Card
                    content={t('serv_5_info')}
                    icon={MdSupportAgent}
                    title={t('serv_5_title')}
                />

                <Card
                    content={t('serv_6_info')}
                    icon={MdSecurity}
                    title={t('serv_6_title')}
                />

                <Card
                    content={t('serv_7_info')}
                    icon={FaMoneyBill}
                    title={t('serv_7_title')}
                />

                <Card
                    content={t('serv_8_info')}
                    icon={GiMining}
                    title={t('serv_8_title')}
                />
            </div>

            <Element name={t('serv_1_title')} className="element" />
            <BuildingSection />

            <Element name={t('serv_2_title')} className="element" />
            <HospitalSection />

            <Element name={t('serv_3_title')} className="element" />
            <EmployementSection />

            <Element name={t('serv_4_title')} className="element" />
            <LogistiqueSection />

            <Element name={t('serv_5_title')} className="element" />
            <ConsultingSection />

            <Element name={t('serv_6_title')} className="element" />
            <SecuritySection />

            <Element name={t('serv_7_title')} className="element" />
            <FinanceSection />
        </div>
    )
}