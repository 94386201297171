import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const Carousel = ({ media }) => {
    const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
    const [progress, setProgress] = useState(0);

    const nextMedia = () => {
        setCurrentMediaIndex((prevIndex) =>
            prevIndex === media.length - 1 ? 0 : prevIndex + 1
        );
        setProgress(0);
    };

    const prevMedia = () => {
        setCurrentMediaIndex((prevIndex) =>
            prevIndex === 0 ? media.length - 1 : prevIndex - 1
        );
        setProgress(0);
    };

    const handleProgressBarClick = (index) => {
        setCurrentMediaIndex(index);
        setProgress(0);
    };

    useEffect(() => {
        let interval;
        if (media[currentMediaIndex].type === 'video') {
            const video = document.getElementById('carousel-video');
            const handleLoadedData = () => {
                const duration = video.duration;
                interval = setInterval(() => {
                    setProgress((prevProgress) => {
                        const newProgress = prevProgress + (100 / duration);
                        if (newProgress >= 100) {
                            nextMedia();
                            return 0;
                        }
                        return newProgress;
                    });
                }, 1000); // Update progress every second
            };
            video.addEventListener('loadeddata', handleLoadedData);
            return () => {
                video.removeEventListener('loadeddata', handleLoadedData);
                clearInterval(interval);
            };
        } else {
            interval = setInterval(() => {
                setProgress((prevProgress) => {
                    const newProgress = prevProgress + (100 / 15);
                    if (newProgress >= 100) {
                        nextMedia();
                        return 0;
                    }
                    return newProgress;
                });
            }, 1000); // Update progress every second
            return () => clearInterval(interval);
        }
    }, [currentMediaIndex]);

    useEffect(() => {
        setProgress(0);
    }, [currentMediaIndex]);

    const renderMedia = () => {
        const currentMedia = media[currentMediaIndex];
        if (currentMedia.type === 'photo') {
            return <img className="object-cover w-full h-full rounded-3xl" src={currentMedia.url} alt="Photo" />;
        } else if (currentMedia.type === 'video') {
            return (
                <video id='carousel-video' className="object-cover w-full h-full rounded-3xl" src={currentMedia.url} autoPlay muted playsInline controls onEnded={nextMedia} />

            );
        }
    };

    const renderProgressBar = () => {
        return media.map((_, index) => (
            <div
                key={index}
                className={`w-full rounded-full flex justify-center m-2 ${currentMediaIndex === index ? 'bg-blue-400' : 'bg-gray-300'}`}
                style={{ height: '7px', cursor: 'pointer' }}
                onClick={() => handleProgressBarClick(index)}
            >
                {currentMediaIndex === index && (
                    <div
                        className="h-full bg-white"
                        style={{ width: `${progress}%` }}
                    ></div>
                )}
            </div>
        ));
    };

    return (
        <div className="relative">
            <div className="flex items-center justify-center w-full relative mb-4">
                {renderMedia()}
                <div className="absolute bottom-1 w-full flex rounded">
                    {renderProgressBar()}
                </div>
                <button
                    className="absolute top-1/2 left-4 transform -translate-y-1/2 focus:outline-none"
                    onClick={prevMedia}
                >
                    <FaArrowLeft className="h-8 w-8 text-gray-500" />
                </button>
                <button
                    className="absolute top-1/2 right-4 transform -translate-y-1/2 focus:outline-none"
                    onClick={nextMedia}
                >
                    <FaArrowRight className="h-8 w-8 text-gray-500" />
                </button>
            </div>
        </div>
    );
};

export default Carousel;
