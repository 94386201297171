import { FaTruck } from "react-icons/fa"

import Logistique from '../../assets/img/Logistique.jpg';
import { useTranslation } from 'react-i18next';

export const LogistiqueSection = () => {
    const { t } = useTranslation();
    return (
        <div>
            <div className='flex gap-3 justify-left text-3xl mt-28'>
                <span className='font-bold flex items-center gap-2'><FaTruck size={40} className='text-[#87ceeb]' />SOFKAM-{t('serv_4_title')}</span>
            </div>
            <div className='flex justify-leftt'>
                <div className='min-w-[150px] h-[5px] bg-[#87ceeb] rounded-full mt-5' />
            </div>


            <div className='flex flex-col justify-left text-left mt-5'>
                <span className='text-xl font-bold '>{t('serv4_p1')}</span>
                <span className='mt-5'>{t('serv4_p2')} </span>
            </div>

            <div className='mt-5 flex flex-col'>
                <div className='flex flex-wrap'>
                    <div className='w-full xl:w-1/2 p-4'>
                        <img src={Logistique} alt="Logistique" className='w-full h-auto rounded-3xl' />
                    </div>
                    <div className='flex items-center  py-10 w-full xl:w-1/2 p-4 '>
                        <div className='bg-[#87ceeb] rounded-3xl p-8 font-bold text-lg flex flex-col ml-5'>
                            <span>- {t('s4-info-1')}</span>
                            <span>- {t('s4-info-2')}</span>
                            <span>- {t('s4-info-3')}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}