import React from 'react';
import { useSpring, animated } from 'react-spring';
import { FaChevronDown } from 'react-icons/fa';

const BouncingIcon = () => {
    const props = useSpring({
        to: { transform: 'translateY(5px)' },
        from: { transform: 'translateY(-5px)' },
        config: { duration: 400, loop: true },
    });

    return (
        <div className="relative">
            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 cursor-pointer">
                <animated.span style={props} className="bounce">
                    <FaChevronDown size={50} className='text-[#87ceeb]' />
                </animated.span>
            </div>
        </div>
    );
};

export default BouncingIcon;
