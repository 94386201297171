import { useEffect } from 'react';
import './App.css';
import { AboutUs } from './Component/AboutUs';
import BouncingIcon from './Component/BouncingIcon';
import { ContactUs } from './Component/ContactUs';
import { Footer } from './Component/Footer';
import { Header } from './Component/Header';
import { Home } from './Component/Home';
import { Services } from './Component/Services';
import { Element, Link } from 'react-scroll';
import moment from 'moment'

import axios from 'axios';
const url = process.env.REACT_APP_URL;

function App() {

    const enregistrerVue = async () => {
        try {
            const pays = await axios.get('https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=37.7749&longitude=-122.4194&localityLanguage=fr');
            const date = moment().format("YYYY-MM-DD HH:mm:ss");
            const vue = {
                pays: pays.data.countryName,
                date
            };
            axios.post(url + '/saveVue', {
                vue
            }).then((response) => {
                if (response) {

                }
            })
        } catch (erreur) {
            console.error('Erreur lors de l\'enregistrement de la vue :', erreur);
        }
    };

    useEffect(() => {
        enregistrerVue();
    }, []);

    return (
        <div className='min-w-[356px] overflow-auto'>
            <div className="h-screen  flex flex-col">
                <Header />

                <Element name="home" className="element">
                    <div className='min-h-[2px] max-w-[2px]'></div>
                </Element>

                <div className='flex-grow relative' id='home'>
                    <Home />
                    <Link to="services" smooth={true} duration={500}>
                        <BouncingIcon />
                    </Link>
                </div>
            </div>

            <Element name="services" className="element">
                <Services />
            </Element>
            <Element name="aboutUs" className="element">
                <AboutUs />
            </Element>
            <Element name="contactUs" className="element">
                <ContactUs />
            </Element>

            <Footer />
        </div>
    );
}

export default App;
