
import { FaBuilding } from 'react-icons/fa';
import Fond1 from '../../assets/img/fond1.jpg';
import Fond2 from '../../assets/video/fond2.mp4';
import Ream from '../../assets/img/reamenagement.jpeg';
import Build from '../../assets/video/build.mp4';
import { useTranslation } from 'react-i18next';
import Carousel from '../Carroussel';

export const BuildingSection = () => {
    const { t } = useTranslation();
    return (
        <div>
            <div className='flex gap-3 justify-left text-3xl mt-28'>
                <span className='font-bold flex items-center gap-2'><FaBuilding size={40} className='text-[#87ceeb]' />SOFKAM {t('serv_1_title')}</span>
            </div>
            <div className='flex justify-leftt'>
                <div className='min-w-[150px] h-[5px] bg-[#87ceeb] rounded-3xl mt-5' />
            </div>

            <div className='flex flex-col justify-left text-left mt-5'>
                <span className='text-xl font-bold '>{t('serv1_p1')}</span>
                <span className='mt-5'>{t('serv1_p2')}</span>
            </div>

            <div className='mt-5 flex flex-col'>
                <span className='font-bold text-xl text-[#87ceeb]'>A. {t('s1_sub1_title')}</span>
                <div className='flex flex-wrap'>
                    <div className='w-full xl:w-1/2 p-4'>
                        {/* <img src={BuildA} alt="Building A" className='w-full h-auto rounded-3xl' /> */}
                        <Carousel media={
                            [
                                { type: 'video', url: Fond2 },
                                { type: 'photo', url: Fond1 },
                                { type: 'video', url: Build },
                            ]
                        } />
                    </div>
                    <div className='flex items-center  py-10 w-full xl:w-1/2 p-4 '>
                        <div className='bg-[#97d8f1] rounded-3xl p-8 font-bold text-lg'>
                            {t('s1_sub1_info')}
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-5 flex flex-col'>
                <span className='font-bold text-xl text-[#87ceeb]'>B. {t('s1_sub2_title')}</span>
                <div className='flex flex-wrap'>
                    <div className='flex items-center  py-10 w-full xl:w-1/2 p-4 '>
                        <div className='bg-[#87ceeb] rounded-3xl p-8 font-bold text-lg'>
                            {t('s1_sub2_info')}
                        </div>
                    </div>
                    <div className='w-full xl:w-1/2 p-4'>
                        <img src={Ream} alt="Building A" className='w-full h-auto rounded-3xl' />
                    </div>
                </div>
            </div>
        </div>

    )
}