import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Importez les bibliothèques nécessaires pour i18next
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importez les fichiers de traduction ici
import translationFr from './lang/fr.json'; // Assurez-vous d'ajuster le chemin correctement
import translationEn from './lang/en.json'; // Assurez-vous d'ajuster le chemin correctement
import translationSp from './lang/sp.json'; // Assurez-vous d'ajuster le chemin correctement
import translationLn from './lang/ln.json'; // Assurez-vous d'ajuster le chemin correctement
import translationSw from './lang/sw.json'; // Assurez-vous d'ajuster le chemin correctement

// Initialisez i18next
i18n
  .use(initReactI18next)
  .init({
    resources: {
      fr: { translation: translationFr },
      en: { translation: translationEn },
      sp: { translation: translationSp },
      ln: { translation: translationLn },
      sw: { translation: translationSw },
    },
    lng: sessionStorage.getItem('i18next') || 'en', // Utilisez la langue du sessionStorage ou 'en' par défaut
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });


// Rendez votre application une fois i18next initialisé
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
