
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { FaLinkedin } from 'react-icons/fa';

export const Footer = () => {
    const { t } = useTranslation();
    return (
        <div className='  text-[#cfcdcd] text-lg px-10 bg-gray-700 pt-16 pb-24 '>
            <div className='flex sm:flex-row flex-col'>
                <div className="flex-grow lg:w-1/3 sm:w-full pr-24">
                    <div className='text-[#87ceeb] font-bold text-2xl'>{t('F-title-1')}</div>
                    <div className='mt-5 '>
                        {t('F1-info-1')}
                    </div>

                    <div className='mt-5 flex flex-col'>
                        <span> {t('F1-adr-title')}</span>
                        <span>
                            {t('F1-adr-info')}
                        </span>
                    </div>

                    <div className='mt-3 flex justify-between  xl:flex-row flex-col'>
                        <span> {t('email')} </span>
                        <span className='text-blue-400'>: Admin@sofkam.com</span>
                    </div>

                    <div className='mt-2 flex flex-col '>
                        <div className='flex justify-between  xl:flex-row flex-col'> <span>{t('phoneUSA')}</span> <span className='text-blue-400'>: +1 305 305 3055</span></div>
                        <div className='flex justify-between  xl:flex-row flex-col'> <span>{t('alterUSA')} </span><span className='text-blue-400'>: +1 305 607 2284</span></div>
                    </div>

                    <div className='mt-5 flex flex-col'>
                        <div className='flex justify-between  xl:flex-row flex-col'><span> {t('phoneRDC')}</span> <span className='text-blue-400'>: +243 81 000 0929</span></div>
                        <div className='flex justify-between  xl:flex-row flex-col'><span>{t('alterRDC')}</span> <span className='text-blue-400'>: +243 99 000 0928</span></div>
                    </div>
                </div>

                <div className="flex-grow lg:w-1/3 sm:w-full">
                    <div className='text-[#87ceeb] font-bold text-2xl'>{t('F-title-2')}</div>
                    <div className='flex flex-col gap-12 mt-5 font-bold text-2xl'>
                        <Link to="home" smooth={true} duration={500}>
                            <span className='cursor-pointer hover:underline hover:text-blue-400'>{t('H1')}</span>
                        </Link>

                        <Link to="services" smooth={true} duration={500}>
                            <span className='cursor-pointer hover:underline hover:text-blue-400'>{t('H2')}</span>
                        </Link>

                        <Link to="aboutUs" smooth={true} duration={500}>
                            <span className='cursor-pointer hover:underline hover:text-blue-400'>{t('H3')}</span>
                        </Link>

                        <Link to="contactUs" smooth={true} duration={500}>
                            <span className='cursor-pointer hover:underline hover:text-blue-400'>{t('H4')}</span>
                        </Link>
                    </div>
                </div>

                <div className="flex-grow lg:w-1/3 sm:w-full">
                    <div className='text-[#87ceeb] font-bold text-2xl'>{t('F-title-3')}</div>
                    {/* Ajoutez ici votre formulaire d'abonnement à la newsletter */}
                    <div className="mt-5">
                        <input className="block w-full rounded-md border-0 h-14 p-2 focus:outline-none text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#87ceeb] text-sm sm:text-2xl sm:leading-6" id="First Name" type="text" placeholder={t('F3-field1')} />
                    </div>
                    <div className="mt-5">
                        <input className="block w-full rounded-md border-0 h-14 p-2 focus:outline-none text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#87ceeb] text-sm sm:text-2xl sm:leading-6" id="Last name" type="text" placeholder={t('F3-field2')} />
                    </div>
                    <div className="mt-5">
                        <input className="block w-full rounded-md border-0 h-14 p-2 focus:outline-none text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#87ceeb] text-sm sm:text-2xl sm:leading-6" id="Email" type="text" placeholder={t('F3-field3')} />
                    </div>
                    <div className='flex items-center w-full mt-3'>
                        <button className="text-red hover:before:bg-redborder-[#87ceeb] relative h-[50px] w-full overflow-hidden border border-[#87ceeb] bg-white px-3 text-[#87ceeb] shadow-2xl transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-[#87ceeb] before:transition-all before:duration-500 hover:text-white hover:shadow-[#87ceeb] hover:before:left-0 hover:before:w-full">
                            <span className="relative z-10">{t('F3-Btn')}</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className='mt-10 flex flex-col items-center justify-center'>
                <span>© 2024 SOFKAM. {t('final')} </span>
                <div className='flex items-center sm:flex-row flex-col  gap-2'>
                    <div>Designed & built by</div>
                    <div >
                        <a href='https://cd.linkedin.com/in/shams-kibonge-5623a7260' target='_blank' className='flex items-center gap-2 cursor-pointer'>
                            <span className='text-blue-500 underline'>Shams Kibonge.</span>
                            <span><FaLinkedin size={20} className='text-[#0068C2]' /></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}